<template>
    <v-container>
      <app-loader v-if="loading"></app-loader>
        <header class="text-center text-md-start">
            <span class="display-2 text-primary-color font-weight-black my-5 text-h4 ">Documentación complementaria</span>
        </header>

        <v-form ref="RegistroBanco">
        <v-row class="my-6"  >

            <v-col cols="12" class="pb-1">
                <span class="font-weight-black">Datos bancarios</span>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-select
                :items="Listbancos"
                item-text="nombre"
                item-value="id"
                outlined label="Selecciona un banco*"
                v-model="RegistroBanco.bancoId"
                :rules="[v => !!v || 'El campo es requerido']"
                >
            </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                :rules="[v => !!v || 'El campo es requerido', v => /^[0-9]+$/.test(v) || 'Por favor, ingresa solo números']"
                v-model="RegistroBanco.NCuenta"
                maxlength="20"
                counter=""
                outlined
                label="Número de cuenta*"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-file-input outlined label="Adjuntar documento*" prepend-icon="" append-icon="mdi-tray-arrow-down"
                    v-model="RegistroBanco.file"
                    hint="Archivos permitidos PDF, JPG, JPEG, PNG y tamaño máx. 2MB"
                    :accept="tiposArchivoDocumentos"
                    :rules="[v => !!v || 'El campo es requerido']"
                    @change="changeFile('bancario')"
                    ref="docBancario"
                    persistent-hint>
                  </v-file-input>
                  <!-- :error-messages="errorDocumentoBanco" -->
            </v-col>
            <v-col class="offset-md-1">
                <v-btn color="#22234a" class="my-text-button" dark rounded @click="GuardarDatosBancarios()">{{ dataBanco.length === 0 || dataBanco[0]?.cuenta_bancaria === null ? 'Agregar'
                    : 'Modificar' }}</v-btn>
            </v-col>
          </v-row>
          </v-form>
          <ChatComponent />
        <v-data-table  :headers="headersBanco" :items="dataBanco" hide-default-footer class="elevation-1"
            no-data-text="No hay datos disponibles">
          <template v-slot:item.actions="{ item }">
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="showDocument(userInfo.id_persona,'B',1)">
                mdi-eye
            </v-icon>
        </template>
        <span>Vista</span>
    </v-tooltip>

<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="openBankDataDialog(item)">
            mdi-delete
        </v-icon>
    </template>
    <span>Eliminar</span>
</v-tooltip>

<v-dialog v-model="bankDataDialog" persistent max-width="400px">
    <v-card>
      <v-card-title class="d-flex justify-center text-center">
          <v-row class="justify-end">
        <v-col cols="2" class="pa-0 d-flex justify-end">
          <v-btn  icon @click="bankDataDialog = false">
            <v-icon class="mdi mdi-close"></v-icon>
        </v-btn>
        </v-col>
      </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center text-center flex-column">
          <span class="headline" style="color:#1C1E4D">Confirmación</span>
            <p>¿Estás seguro que deseas eliminar este registro?</p>
        </v-card-text>
        <v-card-actions>
          <v-row :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''" style="margin: -5px;">
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'text-right'">
            <v-btn rounded outlined @click="bankDataDialog = false"
                style="color: #1C1E4D; background-color: white; border-color: #1C1E4D; width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">
                Cancelar
            </v-btn>

            </v-col>
            <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'text-left'">
                <v-btn color="#22234a" dark rounded @click="confirmBankDataDeletion()" style="width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">Eliminar</v-btn>
              </v-col>
          </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

        </v-data-table>
        <v-form ref="RegistraExamenMedico">
        <v-row class="mt-5">

            <v-col cols="12" class="pb-1">
                <span class="font-weight-black">Exámenes médicos</span>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-select outlined :items="ListTiposExamenes" item-text="nombre" item-value="id" label="Examen*"
                  :rules="[v => !!v || 'El campo es requerido']"
                    v-model="RegistraExamenMedico.examenId"></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-file-input outlined label="Adjuntar documento*" prepend-icon="" append-icon="mdi-tray-arrow-down"
                  :rules="[v => !!v || 'El campo es requerido']"
                    v-model="RegistraExamenMedico.archivo"
                    hint="Archivos permitidos PDF, JPG, JPEG, PNG y tamaño máx. 2MB"
                    :accept="tiposArchivoDocumentos"
                    @change="changeFile('medico')"
                    ref="docMedico"
                    persistent-hint
                    ></v-file-input>
            </v-col>
            <v-col md="3"></v-col>
            <v-col class="offset-md-1">
                <v-btn color="#22234a" class="my-text-button" dark rounded @click="GuardarExamenMedico()">Agregar</v-btn>
            </v-col>
        </v-row>
        </v-form>

        <v-data-table :headers="headersExamenes" :items="examenes" item-text="TipoExamenMedico.nombre" item-value="id"
            hide-default-footer class="elevation-1" style="">
          <template v-slot:item.actions="{ item }">
    <!-- Tooltip para el icono de vista -->
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="showDocument(userInfo.id_persona,'E',item.TipoExamenMedico.id)">
                mdi-eye
            </v-icon>
        </template>
        <span>Vista</span>
    </v-tooltip>

    <!-- Tooltip para el icono de eliminar -->
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="openDialog(item)">
            mdi-delete
        </v-icon>
    </template>
    <span>Eliminar</span>
</v-tooltip>
    <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title class="d-flex justify-center text-center">
          <v-row class="justify-end">
        <v-col cols="2" class="pa-0 d-flex justify-end">
          <v-btn  icon @click="dialog = false">
            <v-icon class="mdi mdi-close"></v-icon>
        </v-btn>
        </v-col>
      </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center text-center flex-column">
          <span class="headline" style="color:#1C1E4D">Confirmación</span>
            <p>¿Estás seguro que deseas eliminar este registro?</p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#22234a"  rounded outlined @click="dialog = false"
            style="color: #1C1E4D; background-color: white; border-color: #1C1E4D; width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">
              Cancelar</v-btn>
            <v-btn color="#22234a" dark rounded @click="confirmDelete(selectedItem)"
            style="width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">
            Eliminar
          </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
        </v-data-table>
        <v-form ref="RegistrarDatosDocumento">
            <v-row class="mt-5">
            <v-col cols="12" class="pb-1">
                <span class="font-weight-black">Documentos</span>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-select :items="ListTiposDocumentos" outlined item-text="nombre" item-value="id" label="Documento*"
                    :rules="[v => !!v || 'El campo es requerido']"
                    v-model="RegistrarDatosDocumento.documentoId"></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="3">
                <v-file-input outlined label="Adjuntar documento*" prepend-icon="" append-icon="mdi-tray-arrow-down"
                  v-model="RegistrarDatosDocumento.file"
                  :rules="[v => !!v || 'El campo es requerido']"
                  hint="Archivos permitidos PDF, JPG, JPEG, PNG y tamaño máx. 2MB"
                  :accept="tiposArchivoDocumentos"
                  @change="changeFile('documentos')"
                  ref="docDocumentos"
                  persistent-hint
                ></v-file-input>
            </v-col>
            <v-col md="3"></v-col>
            <v-col class="offset-md-1">
                <v-btn color="#22234a" class="my-text-button" dark rounded @click="GuardarDatosDocumento()">Agregar</v-btn>
            </v-col>
        </v-row>
        </v-form>
        <v-data-table :headers="headersDocumentos" :items="documentos" item-text="TipoDocumento.nombre" item-value="id"
            hide-default-footer class="elevation-1" style="">
<template v-slot:item.actions="{ item }">
    <!-- Tooltip para el icono de vista -->
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }" v-if="item.ruta_archivo!=null && item.ruta_archivo!='empty' && item.ruta_archivo!=''">
            <v-icon class="mr-2" v-bind="attrs" v-on="on" @click="showDocument(userInfo.id_persona,'T',item.TipoDocumento.id)">
                mdi-eye
            </v-icon>
        </template>
        <span>Vista</span>
    </v-tooltip>

    <!-- Tooltip para el icono de eliminar -->
   <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="openDocumentDialog(item)">
            mdi-delete
        </v-icon>
    </template>
    <span>Eliminar</span>
</v-tooltip>
</template>

        </v-data-table>
        <template>
        <v-dialog v-model="showDialogDocumento" max-width="800px">
            <v-card class="pb-2">
              <embed
                width="100%"
                height="700"
                :src="verDocuemnto"
                :type="typeDoc"
              />
              <v-card-actions>
                <v-btn color="#22234a" class="my-text-button" dark rounded @click="showDialogDocumento = false" >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <v-dialog v-model="documentDialog" persistent max-width="400px">
    <v-card>
        <v-card-title class="d-flex justify-center text-center">
          <v-row class="justify-end">
        <v-col cols="2" class="pa-0 d-flex justify-end">
          <v-btn  icon @click="documentDialog = false">
            <v-icon class="mdi mdi-close"></v-icon>
        </v-btn>
        </v-col>
      </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center text-center flex-column">
          <span class="headline" style="color:#1C1E4D">Confirmación</span>
            <p>¿Estás seguro que deseas eliminar este registro?</p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#22234a" rounded outlined @click="documentDialog = false"
            style="color: #1C1E4D; background-color: white; border-color: #1C1E4D; width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">
              Cancelar</v-btn>
            <v-btn color="#22234a" dark rounded @click="confirmDocumentDeletion()"
            style="width: 85px; height: 35px; font-size: 12px; text-transform: capitalize;">
              Eliminar
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
</v-dialog>
    </v-container>
</template>

<script>
import AppLoader from "@/components/AppLoader.vue";
import ChatComponent from "@/components/ChatComponent.vue";
import {mapMutations, mapState} from "vuex";
export default {
  components: {AppLoader, ChatComponent},
    data: () => ({
       documentDialog: false, // Controla la visibilidad del modal de documentos
      selectedDocument: null,
       bankDataDialog: false, // Controla la visibilidad del modal de datos bancarios
      selectedBankItem: null,
      validTypeDoc: false,
      dialog: false, // Controla la visibilidad del modal
      selectedItem: null, // Almacena el ítem seleccionado para eliminar
      loading: false, // Estado de carga
        showDeleteConfirmation: false,
        documentToDelete: null,
        persona: 7,
        tiposArchivoDocumentos: ".pdf,.jpg,.jpeg,.png",
        headersBanco: [
            {
                text: "Banco",
                value: "nombre_acreedor_banco",
                align: "center"
            },
            {
                text: "Número de cuenta",
                value: "cuenta_bancaria",
                align: "center"
            },
            {
                text: "Acciones",
                value: "actions",
                align: "center"
            },
        ],
        dataBanco: [],
        headersExamenes: [
            {
                text: "Exámen",
                value: "TipoExamenMedico.nombre",
                align: "center"
            },
            {
                text: "Acciones",
                value: "actions",
                align: "center"
            }
        ],
        examenes: [],
        headersDocumentos: [
            {
                text: "Documento",
                value: "TipoDocumento.nombre",
                align: "center"
            },
            {
                text: "Acciones",
                value: "actions",
                align: "center"
            }
        ],
        documentos: [],
        RegistroBanco: {
          bancoId: null,
          NCuenta: null,
          file: null,
        },
        RegistraExamenMedico: {
            examenId: null,
            archivo: null,
            id_persona: 2
        },
        RegistrarDatosDocumento: {
            documentoId: null,
            file: null
        },
        Listbancos: null,
        ListTiposExamenes: null,
        ListTiposDocumentos: null,
        showDialogDocumento: false,
        verDocuemnto: null,
        typeDoc: null,
        loading: false,

    }),



    methods: {
        numeroValidation(value) {
          const numRegex = /^[0-9]+$/;
          if (!value && !this.$v.RegistroBanco.NCuenta.$dirty) {
            return true;
          }

          if (!value) {
            return 'El campo es requerido';
          } else if (!numRegex.test(value)) {
            return 'Por favor, ingresa solo números';
          }
          return true;
        },
        numeroValidation(value) {
          const numRegex = /^[0-9]+$/;
          if (!value && !this.$v.RegistroBanco.NCuenta.$dirty) {
            return true;
          }

          if (!value) {
            return 'El campo es requerido';
          } else if (!numRegex.test(value)) {
            return 'Por favor, ingresa solo números';
          }
          return true;
        },
        async getListadoBancos() {
          this.loading = true;
          try{
            const response = await this.services.expediente.getListadoBancos()
            if (response.status == 200) {
              this.Listbancos = response.data
            }
          }catch (e) {

          }finally {
            this.loading = false;
          }
        },

        async getListadoTiposExamenes() {
          this.loading = true;
          try {
            const response = await this.services.examenesMedicos.getListadoTiposExamenes();
            if (response.status == 200) {
              this.ListTiposExamenes = response.data;
            }
          }catch (e) {

          }finally {
            this.loading = false;
          }
        },

        async getExamenesMedicos() {
          this.loading = true;
          try {
            if(typeof this.userInfo.id_persona !== 'undefined'){
              const response = await this.services.datosPersona.getExemenesMedicos(this.userInfo.id_persona);
              if (response.status == 200) {
                this.examenes = response.data
              }
            }
          }catch (e) {

          }finally {
            this.loading = false;
          }
        },

        async getDocumentos() {
          this.loading = true;
          try {
            if(typeof this.userInfo.id_persona !== 'undefined'){
              const response = await this.services.datosPersona.getDocumentos(this.userInfo.id_persona);
              if (response.status == 200) {
                this.documentos = response.data
              }
            }
          }catch (e) {

          }finally {
            this.loading = false;
          }
        },

        async getTipoDocumentos() {
          this.loading = true;
          try {
            const response = await this.services.documento.getTiposDocumentos();
            if (response.status == 200) {
              this.ListTiposDocumentos = response.data;
            }
          }catch (e) {

          }finally {
            this.loading = false;
          }
        },

    async GuardarDatosBancarios() {
          // this.$refs.RegistroBanco.reset();
          // this.$refs.RegistroBanco.validate();
          if(!this.$refs.RegistroBanco.validate()) {
              return;
          }

          if (this.isTokenValido(this.token)) {
            const valorNCuenta = this.RegistroBanco.NCuenta;
            const validacionNCuenta = this.numeroValidation(valorNCuenta);
            if (validacionNCuenta !== true) {
              this.temporalAlert({
                show: true,
                message: validacionNCuenta,
                type: "error",
              });
              return;
            }

            if (this.RegistroBanco.bancoId && this.RegistroBanco.NCuenta) {
              const data = new FormData();

              data.append('id_acreedor_banco', this.RegistroBanco.bancoId)
              data.append('cuenta_bancaria', this.RegistroBanco.NCuenta)
              data.append('id_persona', this.userInfo.id_persona)
              data.append('archivo', this.RegistroBanco.file)

              const datos = {
                data: data,
                id_persona: this.userInfo.id_persona,
              }

              try {
                this.loading = true;
                const response = await this.services.expediente.putRegistroBanco(datos);
                if (response.status == 200) {
                  this.RegistroBanco.bancoId = null;
                  this.RegistroBanco.file = null;
                  this.RegistroBanco.NCuenta = null;
                  this.getDatoBancario();
                  this.temporalAlert({
                    show: true,
                    message: response.data.message,
                    type: "success",
                  });
                  this.$refs.RegistroBanco.reset()
                }
              } catch (e) {
                // Manejar el error, si es necesario
              } finally {
                this.loading = false;
              }
            } else {
              this.temporalAlert({
                show: true,
                message: "Debe completar los campos de los datos bancarios ",
                type: "error",
              });
            }
          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe volver a iniciar sesión"
            });
            this.$router.replace({name: "login"});
          }
        },

        async GuardarExamenMedico() {

          if(!this.$refs.RegistraExamenMedico.validate()) {
            return;
          }


          if(this.isTokenValido(this.token)) {
            if (this.RegistraExamenMedico.examenId && this.RegistraExamenMedico.archivo) {
                    const data = new FormData();
                    data.append('id_tipo_examen_medico', this.RegistraExamenMedico.examenId);
                    data.append('id_persona', this.userInfo.id_persona);
                    data.append('archivo', this.RegistraExamenMedico.archivo);
                    try {
                    this.loading = true;
                    const response = await this.services.datosPersona.AddExamenMedico(data);
                    if (response.status == 200 || response.status == 201) {
                        this.RegistraExamenMedico.examenId = null;    // Limpiar el campo examenId
                        this.RegistraExamenMedico.archivo = null;
                        this.getExamenesMedicos();
                        this.temporalAlert({
                            show: true,
                            message: response.data.message || 'Los datos se guardaron con éxito',
                            type: "success",
                        });
                        this.$refs.RegistraExamenMedico.reset()
                    }
                    } catch (error) {

                            this.temporalAlert({
                                show: true,
                                message:  error.response.data.err.description || 'No se pudo guardar el examen',
                                type: "error",
                            });
                        }finally {
                            this.loading = false;
                        }
            } else {
                this.temporalAlert({
                        show: true,
                        message: "Debe completar los campos de Exámenes médicos",
                        type: "error",
                    });
            }

          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe de volver a iniciar sesión"
            })
            this.$router.replace({name: "login"})
          }

        },

        async GuardarDatosDocumento() {
          if(!this.$refs.RegistrarDatosDocumento.validate()) {
            return;
          }
          if(this.isTokenValido(this.token)) {
            if (this.RegistrarDatosDocumento.documentoId && this.RegistrarDatosDocumento.file) {
                const data = new FormData();
                data.append('id_tipo_documento', this.RegistrarDatosDocumento.documentoId);
                data.append('id_persona', this.userInfo.id_persona);
                data.append('archivo', this.RegistrarDatosDocumento.file);
                data.append('numero_documento', this.RegistrarDatosDocumento.numero_documento || null);
                try {
                  this.loading = true;
                    const response = await this.services.datosPersona.addDocumento(data);

                    if (response.status == 200 || response.status == 201) {
                        this.RegistrarDatosDocumento.file = null,
                        this.RegistrarDatosDocumento.documentoId = null,
                        this.getDocumentos();
                        this.temporalAlert({
                            show: true,
                            message: response.data.message || 'Los datos se guardaron con éxito',
                            type: "success",
                        });
                        this.$refs.RegistrarDatosDocumento.reset()
                    }
                } catch (error) {
                    this.temporalAlert({
                        show: true,
                        message:  error.response.data.err.description || 'No se pudo guardar el documento',
                        type: "error",
                    });
                }finally {
                  this.loading = false;
                }
            } else {
              this.temporalAlert({
                show: true,
                message: "Debe completar los campos de documentos",
                type: "error",
              });
            }
          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe de volver a iniciar sesión"
            })
            this.$router.replace({name: "login"})
          }
          this.$refs.RegistrarDatosDocumento.resetValidation();
          this.RegistrarDatosDocumento.documentoId = null;
          this.RegistrarDatosDocumento.file = null;
        },

        async getDatoBancario() {
          if(typeof this.userInfo.id_persona !== 'undefined'){
            try {
                this.loading = true;
                const response = await this.services.expediente.getDatoBancario(this.userInfo.id_persona);
                this.dataBanco = response.data[0].cuenta_bancaria ? response.data : [];  //result[0].cuenta_bancaria !== '' ? response.data : {};
            } catch (error) {
                this.temporalAlert({
                    show: true,
                    message: response.data.message || 'No se pudo obtener la información bancaria',
                    type: "error",
                });
            }finally {
              this.loading = false;
            }
          }
        },
      openDocumentDialog(item) {
      this.selectedDocument = item;
      this.documentDialog = true;
    },
   
    async confirmDocumentDeletion() {
      this.documentDialog = ! this.documentDialog

      if (this.selectedDocument) {
      await this.deleteDocumento(this.selectedDocument);
      }
      this.documentDialog = false; // Cierra el modal después de intentar eliminar
      this.selectedDocument = null; // Resetea el ítem seleccionado
    },
        async deleteDocumento(item) {
          if(this.isTokenValido(this.token)) {
            try {
                this.loading = true;
                const response = await this.services.datosPersona.deleteDocumento(item.id)

                await this.getDocumentos()
                this.temporalAlert({
                    show: true,
                    message: response.data.message || 'Los datos se guardaron con éxito',
                    type: "success",
                });
            } catch (error) {
                this.temporalAlert({
                    show: true,
                    message: response.data.message || 'No se pudo obtener la información bancaria',
                    type: "error",
                });
            }finally {
              this.loading = false;
            }

          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe de volver a iniciar sesión"
            })
            this.$router.replace({name: "login"})
          }
        },

    openDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    async confirmDelete() {
      if (this.selectedItem) {
        await this.deleteExamenMedico(this.selectedItem);
      }
      this.dialog = false; // Cierra el modal después de intentar eliminar
      this.selectedItem = null; // Resetea el ítem seleccionado
    },
    async deleteExamenMedico(item) {
      if(this.isTokenValido(this.token)) {
        try {
          this.loading = true;
          const response = await this.services.datosPersona.deleteExamenMedico(item.id);
          await this.getExamenesMedicos();
          this.temporalAlert({
            show: true,
            message: response.data.message || 'Los datos se guardaron con éxito',
            type: "success",
          });
        } catch (error) {
          this.temporalAlert({
            show: true,
            message: error.response?.data.message || 'No se pudo eliminar el registro',
            type: "error",
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.temporalAlert({
          show:true,
          type:"error",
          message:"El token ya expiró, debe de volver a iniciar sesión"
        });
        this.$router.replace({name: "login"});
      }
    },
 openBankDataDialog(item) {
      this.selectedBankItem = item;
      this.bankDataDialog = true;
    },
    async confirmBankDataDeletion() {
      if (this.selectedBankItem) {
        await this.EliminarDatosBancarios();
      }
      this.bankDataDialog = false; // Cierra el modal después de intentar eliminar
      this.selectedBankItem = null; // Resetea el ítem seleccionado
    },
        async EliminarDatosBancarios() {
            // let id = this.RegistroBanco.bancoId
          if(this.isTokenValido(this.token)) {
            let data = {
                id_acreedor_banco: null,
                cuenta_bancaria: null,
            };

            const datos = {
                data: data,
                id_persona: this.userInfo.id_persona
            }
            try {
              this.loading = true;
              const response = await this.services.expediente.putRegistroBanco(datos);
              if (response.status == 200) {
                this.Listbancos = null;
                this.RegistroBanco.NCuenta = null;
                this.getDatoBancario();
                this.getListadoBancos();
                this.temporalAlert({
                  show: true,
                  message: response.data.message,
                  type: "success",
                });
              }
            }catch (e) {

            }finally {
              this.loading = false;
            }

          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe de volver a iniciar sesión"
            })
            this.$router.replace({name: "login"})
          }
        },

        async showDocument(idPersona, tipoConsulta , tipoDocumento) {
          if(this.isTokenValido(this.token)) {
            const params = {
                tipo_consulta: tipoConsulta,
                tipo_documento: tipoDocumento,
                id_persona: idPersona,
            }
            try {
              this.loading = true;
              let response = await this.services.datosPersona.getDocumentoPersona(params);
              if (response.status == 200) {


                //  this.getDoc(response?.data);

                this.showDialogDocumento = true;
                this.verDocuemnto = response?.data.archivo;
                this.typeDoc = response?.data.type;
              }
            }catch (e) {

            }finally {
              this.loading = false;
            }

          } else {
            this.temporalAlert({
              show:true,
              type:"error",
              message:"El token ya expiró, debe de volver a iniciar sesión"
            })
            this.$router.replace({name: "login"})
          }

        },

    changeFile(tipo){
      if(tipo == 'bancario'){
        if(this.RegistroBanco.file){
          const file = this.RegistroBanco.file;
          const fileName = file.name;
          const fileSize = file.size;
          if (!fileName.endsWith('.jpeg') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png') && !fileName.endsWith('.pdf')){
            this.$refs.docBancario.reset(); // reset the input if the file extension is not valid
            this.RegistroBanco.file = null;
            this.temporalAlert({
              show: true,
              message: "El archivo debe tener extensión .pdf, .jpg, .png o .jpeg",
              type: "error",
            });
          } else if (fileSize > 2097152) {
            this.$refs.docBancario.reset(); // reset the input if the file extension is not valid
            this.RegistroBanco.file = null;
            this.temporalAlert({
              show: true,
              message: "El archivo es demasiado grande (> 2 MB)",
              type: "error",
            });
          }
        }
      }else if(tipo == 'medico'){
        if(this.RegistraExamenMedico.archivo){
          const file = this.RegistraExamenMedico.archivo;
          const fileName = file.name;
          const fileSize = file.size;
          if (!fileName.endsWith('.jpeg') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png') && !fileName.endsWith('.pdf')){
            this.$refs.docMedico.reset(); // reset the input if the file extension is not valid
            this.RegistraExamenMedico.archivo = null;
            this.temporalAlert({
              show: true,
              message: "El archivo debe tener extensión .pdf, .jpg, .png o .jpeg",
              type: "error",
            });
          } else if (fileSize > 2097152) {
            this.$refs.docMedico.reset(); // reset the input if the file extension is not valid
            this.RegistraExamenMedico.archivo = null;
            this.temporalAlert({
              show: true,
              message: "El archivo es demasiado grande (> 2 MB)",
              type: "error",
            });
          }
        }
      }else if(tipo == 'documentos'){
        if(this.RegistrarDatosDocumento.file){
          const file = this.RegistrarDatosDocumento.file;
          const fileName = file.name;
          const fileSize = file.size;
          if (!fileName.endsWith('.jpeg') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png') && !fileName.endsWith('.pdf')){
            this.$refs.docDocumentos.reset(); // reset the input if the file extension is not valid
            this.RegistrarDatosDocumento.file = null;
            this.temporalAlert({
              show: true,
              message: "El archivo debe tener extensión .pdf, .jpg, .png o .jpeg",
              type: "error",
            });
          } else if (fileSize > 2097152) {
            this.$refs.docDocumentos.reset(); // reset the input if the file extension is not valid
            this.RegistrarDatosDocumento.file = null;
            this.temporalAlert({
              show: true,
              message: "El archivo es demasiado grande (> 2 MB)",
              type: "error",
            });
          }
        }
      }
    },


    },

    computed: {
    ...mapState(["userInfo", "token"]),

    // errorDocumentoBanco() {
    //   let errors = [];
    //   if (!this.$v.RegistroBanco.file.validTypeDoc) {
    //     errors.push("Tipo de documento no válido");
    //   }
    //   return errors;
    // }

  },

  async  created() {
      this.loading = true;
      setTimeout(async () => {

      await  this.getListadoBancos()
      await  this.getListadoTiposExamenes()
      await this.getTipoDocumentos();

      await this.getDatoBancario();
      await this.getExamenesMedicos();
      await this.getDocumentos();

      this.loading = false;
    }, 2000);

  }
}

</script>

<style></style>
